import React from "react";
import Products from "./products";
import SliderMain from "./slider";


const ShopDisplay =()=>{

    return(
        <>
        <SliderMain/>
        <Products/>
        </>
    )
}


export default ShopDisplay;